import React from "react";
import moment from "moment";

const ListGroupEmployeesJobOffers = ({ group, year }) => {
  const yesterday = moment().subtract(1, "day");
  const startOfMonth = moment().startOf("month");
  const currentEmployeesYesterday = group?.currentEmployeeMap
    ? Object.keys(group.currentEmployeeMap).reduce((acc, email) => {
        if (moment(group.currentEmployeeMap[email].date) <= yesterday) {
          acc++;
        }
        return acc;
      }, 0)
    : 0;
  const currentEmployeesStartOfMonth = group?.currentEmployeeMap
    ? Object.keys(group.currentEmployeeMap).reduce((acc, email) => {
        if (moment(group.currentEmployeeMap[email].date) <= startOfMonth) {
          acc++;
        }
        return acc;
      }, 0)
    : 0;
  const newHiresScheduled = group?.currentEmployeeMap
    ? Object.keys(group.currentEmployeeMap).reduce((acc, email) => {
        if (moment(group.currentEmployeeMap[email].date) > startOfMonth) {
          acc++;
        }
        return acc;
      }, 0)
    : 0;
  const terminationsScheduled = group?.formerEmployeeMap
    ? Object.keys(group.formerEmployeeMap).reduce((acc, email) => {
        if (moment(group.formerEmployeeMap[email].date) > startOfMonth) {
          acc++;
        }
        return acc;
      }, 0)
    : 0;
  return (
    <div>
      {group && year && (
        <div>
          <ol>
            <li>
              Current Employees as of {yesterday.format("MM/DD/YYYY")} ({currentEmployeesYesterday})
            </li>
            <li>
              Employees as of {startOfMonth.format("MM/DD/YYYY")} ({currentEmployeesStartOfMonth})
            </li>
            <li>
              New Hires Scheduled for {startOfMonth.format("MM/DD/YYYY")} or Later ({newHiresScheduled})
            </li>
            <li>
              Terminations Scheduled for {startOfMonth.format("MM/DD/YYYY")} or Later ({terminationsScheduled})
            </li>
            <li>Current Outstanding Job Offers ({group?.jobOfferMap ? Object.keys(group.jobOfferMap).length : 0})</li>
          </ol>
        </div>
      )}
      {group?.currentEmployeeMap && Object.keys(group.currentEmployeeMap).length ? (
        <>
          <h3>Current Employees ({Object.keys(group.currentEmployeeMap).length.toLocaleString()})</h3>
          {Object.keys(group.currentEmployeeMap).map((email, idx) => (
            <div key={idx}>
              {email} - {group.currentEmployeeMap[email].roles?.[year]} - {group.currentEmployeeMap[email].date}
            </div>
          ))}
        </>
      ) : (
        ""
      )}
      {group?.benefitIneligibleEmployeesMap && Object.keys(group.benefitIneligibleEmployeesMap).length && (
        <>
          <h3 style={{ marginTop: "10px" }}>
            Benefit Ineligible Employees ({Object.keys(group.benefitIneligibleEmployeesMap).length.toLocaleString()})
          </h3>
          {Object.keys(group.benefitIneligibleEmployeesMap).map((email, idx) => (
            <div key={idx}>
              {email} - {group.benefitIneligibleEmployeesMap[email].roles?.[year]} -{" "}
              {group.benefitIneligibleEmployeesMap[email].date}
            </div>
          ))}
        </>
      )}
      {group?.formerEmployeeMap && Object.keys(group.formerEmployeeMap).length ? (
        <>
          <h3 style={{ marginTop: "10px" }}>
            Former Employees ({Object.keys(group.formerEmployeeMap).length.toLocaleString()})
          </h3>
          {Object.keys(group.formerEmployeeMap).map((email, idx) => (
            <div key={idx}>
              {email} - {group.formerEmployeeMap[email].roles?.[year]} - {group.formerEmployeeMap[email].date}
            </div>
          ))}
        </>
      ) : (
        ""
      )}
      {group?.jobOfferMap && Object.keys(group.jobOfferMap).length ? (
        <>
          <h3 style={{ marginTop: "10px" }}>Job Offers ({Object.keys(group.jobOfferMap).length.toLocaleString()})</h3>
          {Object.keys(group.jobOfferMap).map((key, idx) => (
            <div key={idx}>
              {group.jobOfferMap[key].email} - {group.jobOfferMap[key].roles?.[year]}
            </div>
          ))}
        </>
      ) : (
        ""
      )}
      {group && !group?.currentEmployeeMap && !group?.formerEmployeeMap && !group?.jobOfferMap ? (
        <div>No emails found</div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ListGroupEmployeesJobOffers;
