import React, { useState, useRef } from "react";
import { getToken } from "services/utils";
import { getGroup, exportUsers } from "services/api/groups.api";
import { Card, message, Form, Input, Button } from "antd";
import { ListGroupEmployeesJobOffers } from "components";
const { Item } = Form;

const ListEmployeeJobOfferPanel = ({}) => {
  const [searchForm] = Form.useForm();
  const [group, setGroup] = useState(null);

  const downloadLink = (href) => {
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", `${group.name}.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const handleSearchGroup = async (changes) => {
    const response = await getGroup({ name: changes.search });
    if (response.status === 200) {
      const group = response.data.group;
      setGroup(group);
    } else {
      message.error(response.response.data.message);
    }
  };

  const handleExportData = async () => {
    try {
      if (!group) return message.error("No group selected");
      const token = await getToken();
      const { status, data } = await exportUsers(group.name, token);
      if (status === 200) {
        const href = window.URL.createObjectURL(data);
        downloadLink(href);
      } else {
        message.error("Internal Server Error");
      }
    } catch (error) {
      message.error("Unknown Error");
      console.log("error", error);
    }
  };

  return (
    <Card title="Employee / Job Offer List">
      <Form
        form={searchForm}
        layout="inline"
        onFinish={handleSearchGroup}
        style={{ marginBottom: "2%" }}
        initialValues={{
          search: "",
        }}
      >
        <Item name="search" rules={[{ required: true, message: "name required!" }]}>
          <Input autoComplete="off" />
        </Item>
        <Item>
          <Button type="primary" htmlType="submit">
            Search
          </Button>
        </Item>
        {group && (
          <div>
            <Button type="primary" onClick={() => handleExportData()}>
              Export Data
            </Button>
          </div>
        )}
      </Form>
      <ListGroupEmployeesJobOffers group={group} />
    </Card>
  );
};

export default ListEmployeeJobOfferPanel;
